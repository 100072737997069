<template>
  <div>
    <ds-header title="工单管理"></ds-header>
    <div class="searchBox">
      <div class="searchBox_form">
        <div class="displayFlex">
          <div class="searchBox_form_type">工单信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="工单编号：">
                <a-input v-model.trim="searchForm.workNumber" style="width: 200px" placeholder="请输入工单编号" />
              </a-form-item>
              <a-form-item label="工单状态：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" style="width: 200px" placeholder="请选择工单状态" v-model="searchForm.workStateList">
                  <a-select-option v-for="item in workerStatusList" :key="item.demandState">
                    {{ item.demandStateName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="项目信息：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" style="width: 200px" placeholder="请选择项目信息" v-model="searchForm.projectIdList">
                  <a-select-option v-for="item in projectMsgList" :key="item.projectId">
                    {{ item.projectName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="企业信息：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" show-search v-model="searchForm.enterpriseIdList" placeholder="输入企业名称以选择"
                  style="width: 200px" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="handleSearch">
                  <a-select-option v-for="item in enterpriseMsg" :key="item.enterpriseId">
                    {{ item.enterpriseName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="需求来源：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" @change="sourceChange"
                  mode="multiple" :maxTagCount="1" :maxTagTextLength="5" style="width: 200px" placeholder="请选择需求来源"
                  v-model="searchForm.demandSourceList">
                  <a-select-option v-for="item in demandSourceList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-if="searchForm.demandSourceList.length" label="需求类型：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" style="width: 200px" placeholder="请先选择需求来源" v-model="searchForm.demandTypeList">
                  <a-select-option v-for="item in demandTypeList" :key="item.demandType">
                    {{ item.demandTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="需求提出日期：">
                <a-range-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                  :placeholder="['最早提出日期', '最晚提出日期']" v-model="orderTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">处理信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="需求发起人：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" show-search v-model="searchForm.createIdList" placeholder="输入查询需求发起者"
                  style="width: 200px" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="initiatorHandleSearch">
                  <a-select-option v-for="item in demandInitiateList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务负责人：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" show-search v-model="searchForm.principalIdList" placeholder="输入查询服务负责人"
                  style="width: 200px" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="headHandleSearch">
                  <a-select-option v-for="item in serviceHeadList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务协作人：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  :maxTagTextLength="5" show-search v-model="searchForm.cooperationIdList" placeholder="输入查询服务协作人"
                  style="width: 200px" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="collaboratorHandleSearch">
                  <a-select-option v-for="item in serviceCollaborateList" :key="item.userId">
                    {{ item.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">归档信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="需求分类：">
                <el-cascader :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择需求分类"
                  v-model="searchForm.classifyList" style="width: 200px" size="small" :options="demandClassifyList"
                  :props="{ multiple: true, value: 'classifyId', label: 'classifyName', children: 'childList' }"
                  collapse-tags clearable></el-cascader>
              </a-form-item>
              <a-form-item label="需求服务类型：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" :maxTagCount="1"
                  :maxTagTextLength="5" mode="multiple" style="width: 200px" placeholder="请选择需求服务类型"
                  v-model="searchForm.demandServerTypeList">
                  <a-select-option v-for="item in demandServiceTypeList" :key="item.demandServerType">
                    {{ item.demandServerTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="是否有效需求：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" :maxTagCount="1"
                  :maxTagTextLength="5" mode="multiple" style="width: 200px" placeholder="请选择是否有效需求"
                  v-model="searchForm.isReasonableDemandList">
                  <a-select-option v-for="item in validOrNotDemand" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="实际解决情况：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" :maxTagCount="1"
                  :maxTagTextLength="5" mode="multiple" style="width: 200px" placeholder="请选择实际解决情况"
                  v-model="searchForm.solutionInformationList">
                  <a-select-option v-for="item in realitySituationList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div v-show="expandShow" class="displayFlex">
          <div class="searchBox_form_type">回访信息：</div>
          <div class="marginLeft flex1">
            <a-form layout="inline" :labelCol="{ style: 'width: 94px' }">
              <a-form-item label="回访方式：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  style="width: 200px" @change="returnVisitTypeChange" placeholder="请选择回访方式"
                  v-model="searchForm.returnVisitTypeList">
                  <a-select-option v-for="item in followUpTypeList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="服务满意度：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  style="width: 200px" placeholder="请选择服务满意度" v-model="searchForm.isSatisfactionList">
                  <a-select-option v-for="item in serviceSatisfactionList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-show="showReturnVisitType" label="响应是否及时：">
                <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple" :maxTagCount="1"
                  style="width: 200px" placeholder="请选择响应是否及时" v-model="searchForm.isPromptResponseList">
                  <a-select-option v-for="item in replytimelyList" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div>
          <a-button type="primary" v-if="saveButton == '1'" @click="showConfirm('shoInsideCreatOrderPop')"> 创建工单
          </a-button>
          <a-button type="primary" v-if="saveButton == '2'" @click="showConfirm('showCreated400Pop')"> 创建工单 </a-button>
          <a-button type="primary" v-if="saveButton == '3'" @click="showConfirm('showIncubatorWorkOrderPop')"> 创建工单 </a-button>
        </div>
      </div>
      <div class="searchBox_but">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">
          <a-icon type="redo" />
          重置
        </a-button>
        <div @click="() => {
          expandShow = !expandShow;
        }
          " v-show="!expandShow" class="expandBox">
          展开 <a-icon type="down" :style="{ color: '#1890ff' }" />
        </div>
        <div @click="() => {
          expandShow = !expandShow;
        }
          " v-show="expandShow" class="expandBox">
          收起 <a-icon type="up" :style="{ color: '#1890ff' }" />
        </div>
      </div>
    </div>
    <a-table @change="pageChange" :columns="columns" style="margin-top: 10px"
      :pagination="pagination.total ? pagination : false" :data-source="tableData" :rowKey="(record) => record.id"
      bordered :scroll="{ x: 3400 }">
      <span slot="project_name_list" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.project_name_list }}
          </template>
          <span class="field">{{ record.project_name_list }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="id = record.workOrderId; showDetailPop = true" size="small"> 查看详情 </a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(1)"
          @click="id = record.workOrderId; showReassignPop = true; reassignType = 1; reassignTitle = '分派工单'" size="small">
          分派工单
        </a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(3)"
          @click="id = record.workOrderId; showReassignPop = true; reassignType = 1; reassignTitle = '重新分派工单'"
          size="small">
          重新分派工单 </a-button>
        <a-popconfirm v-if="record.buttonPermissions.includes(4)"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" placement="topLeft" ok-text="确定" cancel-text="取消"
          @confirm="receiveWorkOrder(record.workOrderId)">
          <template slot="title">
            <p>工单接收后，您将成为此工单的服务负责人，接收后不可取消，确定要接收吗？</p>
          </template>
          <a-button type="link" @click="id = record.workOrderId;" size="small"> 接收工单 </a-button>
        </a-popconfirm>
        <a-button v-if="record.buttonPermissions.includes(5)" type="link"
          @click="id = record.workOrderId; rejectWorkOrderPop = true" size="small"> 拒收工单 </a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(7)"
          @click="id = record.workOrderId; reassignType = 2; showReassignPop = true; reassignTitle = '添加协作人'"
          size="small">
          添加协作人 </a-button>
        <a-button v-if="record.buttonPermissions.includes(6)" type="link"
          @click="id = record.workOrderId; submitServicePop = true; submitService = 6" size="small"> 提交服务 </a-button>
        <!-- .(负责人) -->
        <a-button v-if="record.buttonPermissions.includes(15)" type="link"
          @click="id = record.workOrderId; submitServicePop = true; submitService = 15" size="small"> 提交服务 </a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(8)"
          @click="id = record.workOrderId; userErviceAudit = true; serverDescribe = record.serverDescribe"
          size="small">服务审核</a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(14)"
          @click="id = record.workOrderId; showPromoterErviceAuditPop = true; serverDescribe = record.serverDescribe"
          size="small">服务审核</a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(18)"
          @click="id = record.workOrderId; showExamine400Pop = true; serverDescribe = record.serverDescribe" size="small">
          服务审核 </a-button>
        <!-- 内部/统一客服 -->
        <a-button type="link" v-if="record.buttonPermissions.includes(11)"
          @click="id = record.workOrderId; shoWorkOrderArchivingPop = true" size="small"> 工单归档</a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(9)"
          @click="id = record.workOrderId; showUserDoneVisitPop = true" size="small"> 完成回访 </a-button>
        <a-button v-if="record.buttonPermissions.includes(19)" type="link"
          @click="id = record.workOrderId; showdone400Pop = true" size="small"> 完成回访 </a-button>
        <a-popconfirm v-if="record.buttonPermissions.includes(10)" placement="topRight" ok-text="确定" cancel-text="取消"
          @confirm="cancelFollowUp(record.workOrderId)">
          <template slot="title">
            <p>取消回访后无法再次回访，确认要取消吗？</p>
          </template>
          <a-button type="link" @click="id = record.workOrderId;" size="small"> 取消回访 </a-button>
        </a-popconfirm>
        <a-popconfirm v-if="record.buttonPermissions.includes(20)" placement="topRight" ok-text="确定" cancel-text="取消"
          @confirm="cancelFollowUp(record.workOrderId)">
          <template slot="title">
            <p>取消回访后无法再次回访，确认要取消吗？</p>
          </template>
          <a-button type="link" @click="id = record.workOrderId;" size="small"> 取消回访 </a-button>
        </a-popconfirm>
        <a-button type="link" v-if="record.buttonPermissions.includes(17)"
          @click="id = record.workOrderId; showover400Pop = true; startingTime = record.startingTime" size="small"> 结束工单
        </a-button>
        <!--用户 -->
        <a-button type="link" v-if="record.buttonPermissions.includes(2)"
          @click="id = record.workOrderId; shwoUserOverOrderPop = true; startingTime = record.startingTime"
          size="small">结束工单</a-button>


        <!-- 需求发起人 -->
        <a-button type="link" v-if="record.buttonPermissions.includes(12)"
          @click="id = record.workOrderId; showpromoterEndOrderPop = true; startingTime = record.startingTime"
          size="small">结束工单</a-button>
        <a-button type="link" v-if="record.buttonPermissions.includes(13)"
          @click="id = record.workOrderId; showpromoterEndOrderPop = true; startingTime = record.startingTime"
          size="small">结束工单</a-button>
        <!-- (协助人) -->
      </span>
    </a-table>
    <!-- 详情弹窗 -->
    <popdetail v-if="showDetailPop" :id="id" :show.sync="showDetailPop" />
    <!-- 审核弹窗 -->
    <!-- <popExamine  @resetSearchForm="resetSearchForm" :id="id" :show.sync="showExamine400Pop" /> -->
    <!-- 400创建工单 -->
    <create400Order @resetSearchForm="resetSearchForm" v-if="showCreated400Pop" :show.sync="showCreated400Pop" />
    <!-- 400结束工单 -->
    <over400Order @resetSearchForm="resetSearchForm" v-if="showover400Pop" :startingTime="startingTime" :id="id"
      :show.sync="showover400Pop" />
    <!-- 完成回访 -->
    <done400Visit @resetSearchForm="resetSearchForm" v-if="showdone400Pop" :id="id" :typeState="typeState"
      :show.sync="showdone400Pop" />
    <!-- 完成回访 -->
    <userDoneVisit @resetSearchForm="resetSearchForm" v-if="showUserDoneVisitPop" :id="id"
      :show.sync="showUserDoneVisitPop" />
    <!-- 重新分派 -->
    <reassign @resetSearchForm="resetSearchForm" :type="reassignType" :title=reassignTitle v-if="showReassignPop" :id="id"
      :show.sync="showReassignPop" />
    <!-- 内部结束工单 -->
    <userOverOrder @resetSearchForm="resetSearchForm" :startingTime="startingTime" v-if="shwoUserOverOrderPop" :id="id"
      :show.sync="shwoUserOverOrderPop" />
    <!-- 用户审核工单 -->
    <userErviceAudit @resetSearchForm="resetSearchForm" :serverDescribe="serverDescribe" v-if="userErviceAudit" :id="id"
      :show.sync="userErviceAudit" />
    <!-- 内部审核工单 -->
    <promoterErviceAudit @resetSearchForm="resetSearchForm" v-if="showPromoterErviceAuditPop" :id="id"
      :show.sync="showPromoterErviceAuditPop" />
    <!-- 400审核工单 -->
    <audit400Ervice @resetSearchForm="resetSearchForm" :serverDescribe="serverDescribe" v-if="showExamine400Pop" :id="id"
      :show.sync="showExamine400Pop" />
    <!-- 内部创建工单 -->
    <insideCreatOrder @resetSearchForm="resetSearchForm" v-if="shoInsideCreatOrderPop"
      :show.sync="shoInsideCreatOrderPop" />
    <!-- 孵化器创建工单 -->
    <incubatorWorkOrder @resetSearchForm="resetSearchForm" v-if="showIncubatorWorkOrderPop"
      :show.sync="showIncubatorWorkOrderPop" />
    <!-- 工单归档 -->
    <workOrderArchiving @resetSearchForm="resetSearchForm" v-if="shoWorkOrderArchivingPop" :id="id"
      :show.sync="shoWorkOrderArchivingPop" />
    <!-- 结束工单 -->
    <promoterEndOrder @resetSearchForm="resetSearchForm" :startingTime="startingTime" v-if="showpromoterEndOrderPop"
      :id="id" :show.sync="showpromoterEndOrderPop" />
    <!-- 提交服务 -->
    <submitService @resetSearchForm="resetSearchForm" v-if="submitServicePop" :submitService="submitService" :id="id"
      :show.sync="submitServicePop" />
    <!-- 拒收工单 -->
    <rejectWorkOrder @resetSearchForm="resetSearchForm" v-if="rejectWorkOrderPop" :id="id"
      :show.sync="rejectWorkOrderPop" />
  </div>
</template>
<script>
import moment from 'moment';
import popdetail from '../workOrderDetail/detail.vue'
import popExamine from '../Examine/index.vue'
import create400Order from "../components/create400Order.vue"
import over400Order from "../components/over400Order.vue"
import done400Visit from "../components/done400Visit.vue"
import reassign from "../components/reassign.vue"
import userOverOrder from "../components/userOverOrder.vue"
import userErviceAudit from "../components/userErviceAudit.vue"
import insideCreatOrder from "../components/insideCreatOrder.vue"
import workOrderArchiving from "../components/workOrderArchiving.vue"
import promoterEndOrder from "../components/promoterEndOrder.vue"
import submitService from "../components/submitService.vue"
import rejectWorkOrder from "../components/rejectWorkOrder.vue"
import promoterErviceAudit from "../components/promoterErviceAudit.vue"
import userDoneVisit from "../components/userDoneVisit.vue"
import audit400Ervice from "../components/audit400Ervice.vue"
import incubatorWorkOrder from "../components/incubatorWorkOrder.vue"

import {
  innerWorkOrderList,
  requirementDemandtypeall,
  requirementDemandstate,
  requirementProject,
  requirementEnterprise,
  requirementHandleMsg,
  requirementClassify,
  requirementDemandServertype,
  requirementExport,
  receiveOrder,
  cancelReturnVisit,
  removeWorkOrderDraft,
  getclassFiy
} from '@/api/demandPoolShunyi';
import { columns, searchForm, dataList } from './index';
import Vue from 'vue';
import { Cascader } from 'element-ui';
Vue.use(Cascader);
export default {
  name: 'workOrder',
  data() {
    let searchFormData = JSON.parse(JSON.stringify(searchForm));
    return {
      typeState: false,// 登陆人身份
      showDetailPop: false,
      showExamine400Pop: false,
      showCreated400Pop: false,
      showdone400Pop: false,
      showover400Pop: false,
      showReassignPop: false,
      shwoUserOverOrderPop: false,
      userErviceAudit: false,
      shoInsideCreatOrderPop: false,
      shoWorkOrderArchivingPop: false,
      showpromoterEndOrderPop: false,
      rejectWorkOrderPop: false,//
      submitServicePop: false,// 提交服务
      showPromoterErviceAuditPop: false,// 
      showUserDoneVisitPop: false,// 
      showIncubatorWorkOrderPop: false,// 孵化器创建工单
      columns,
      expandShow: false, //true展开
      orderTime: [],
      searchForm: {
        ...searchFormData,
      },
      tableData: [],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      showReturnVisitType: false,//是否展示响应是否及时
      ...dataList,
      id: '',
      saveButton: '',// 创建工单按钮判断
      submitService: '',// 提交服务（6协作人 15负责人）
      reassignType: '',// 分配类型 1分派工单 2 添加协作人
      reassignTitle: '',// 分配类型 1分派工单 2 添加协作人
      startingTime: '',// 需求开始时间
      haveDraft: '',// 是否存在草稿
      serverDescribe: '',// 引用服务信息
    };
  },
  components: {
    popdetail,//详情
    popExamine,// 审核
    create400Order,// 400c创建工单
    over400Order,// 400结束工单
    done400Visit,// 400完成回访
    reassign,// 重新分派
    userOverOrder,// 用户，统一客服结束工单
    userErviceAudit,// 用户/内部服务审核
    insideCreatOrder,// 内部创建工单
    workOrderArchiving,// 工单归档
    promoterEndOrder,// 内部/需求发起人结束工单
    submitService,// 提交服务
    rejectWorkOrder, // 拒收工单
    promoterErviceAudit,// 需求发起人审核
    userDoneVisit, // 用户完成回访
    audit400Ervice,// 400审核
    incubatorWorkOrder,// 孵化器
  },
  mounted() {
    // 获取列表数据


    this.getProject();
    this.getDemandstate();
    this.getClassify();
    this.getServertype();
  },
  created() {
    this.getTableList();
  },
  methods: {
    recursion(res) {
      for (let index = 0; index < res.length; index++) {
        const item = res[index];
        if (item.childList.length) {
          this.recursion(item.childList)
        } else {
          item.childList = null
        }
      }
    },
    async getTableList() {
      let searchForm = JSON.parse(JSON.stringify(this.searchForm));
      let list = [];
      if (searchForm.classifyList.length) {
        searchForm.classifyList.forEach((item) => {
          item.forEach((i) => {
            list.push(i);
          });
        });
      }
      if (this.orderTime.length) {
        searchForm.startTime = moment(this.orderTime[0]).format('YYYY-MM-DD');
        searchForm.endTime = moment(this.orderTime[1]).format('YYYY-MM-DD');
      } else {
        searchForm.startTime = '';
        searchForm.endTime = '';
      }
      searchForm.classifyList = list;
      const res = await innerWorkOrderList({
        ...searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        header: 1
      });
      if (res.code == 200) {
        this.tableData = res.data.list;
        this.saveButton = res.data.saveButton;
        this.haveDraft = res.data.haveDraft;
        this.pagination.total = res.data.total;
      }
    },
    //获取项目信息
    async getProject() {
      const res = await requirementProject();
      if (res.code == 200) {
        this.projectMsgList = res.data;
      }
    },
    //获取需求分类
    async getClassify() {
      const res = await getclassFiy();
      if (res.code == 200) {
        this.recursion(res.data);
        this.demandClassifyList = res.data;
      }
    },
    //获取工单状态
    async getDemandstate() {
      const res = await requirementDemandstate();
      if (res.code == 200) {
        this.workerStatusList = res.data;
      }
    },
    //获取企业信息
    async handleSearch(value) {
      const res = await requirementEnterprise({ enterpriseName: value });
      if (res.code == 200) {
        this.enterpriseMsg = res.data;
      }
    },
    //需求发起者select输入框
    async initiatorHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 1 });
      if (res.code == 200) {
        this.demandInitiateList = res.data;
      }
    },
    //服务负责人select输入框
    async headHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 2 });
      if (res.code == 200) {
        this.serviceHeadList = res.data;
      }
    },
    //服务协作人select输入框
    async collaboratorHandleSearch(value) {
      const res = await requirementHandleMsg({ userName: value, userType: 3 });
      if (res.code == 200) {
        this.serviceCollaborateList = res.data;
      }
    },
    //需求服务类型
    async getServertype() {
      const res = await requirementDemandServertype();
      if (res.code == 200) {
        this.demandServiceTypeList = res.data;
      }
    },
    //需求来源change事件
    async sourceChange() {
      const res = await requirementDemandtypeall({ demandSourceList: this.searchForm.demandSourceList });
      if (res.code == 200) {
        this.demandTypeList = res.data;
      }
    },
    // 回访方式change事件
    returnVisitTypeChange() {
      let type = false
      this.searchForm.returnVisitTypeList.forEach((item) => {
        if (item == 1) {
          type = true
        }
      });
      this.showReturnVisitType = type
    },
    search() {
      this.pagination.current = 1;
      this.getTableList();
    },
    resetSearchForm() {
      this.searchForm = this.$options.data().searchForm;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.orderTime = [];
      this.getTableList();
    },
    checkDetail(record) {
      this.$router.push({
        path: '/demand-pool-shunyi/workOrder/workOrderDetail',
        query: {
          id: record.workOrderId,
        },
      });
    },
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
    //批量导出
    async exportFile() {
      if (this.orderTime.length) {
        this.searchForm.startTime = moment(this.orderTime[0]).format('YYYY-MM-DD');
        this.searchForm.endTime = moment(this.orderTime[1]).format('YYYY-MM-DD');
      } else {
        this.searchForm.startTime = '';
        this.searchForm.endTime = '';
      }
      try {
        const res = await requirementExport({ ...this.searchForm });
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.setAttribute('download', '工单管理.xlsx');
        a.click();
      } catch (e) {
        this.$message.error('您要导出的数据超过10000条，请缩小筛选范围后重试');
      }
    },
    // 取消回访
    async cancelFollowUp(id) {
      const res = await cancelReturnVisit(id);
      if (res.code == "200") {
        this.$message.success(res.msg);
        this.getTableList();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 接收工单
    receiveWorkOrder(id) {
      receiveOrder(id).then(res => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getTableList();
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    showConfirm(val) {
      let _this = this
      if (this.haveDraft == 1) {
        this.$confirm({
          title: '使用草稿',
          content: '是否使用草稿？',
          onOk() {
            _this[val] = true
          },
          onCancel() {
            removeWorkOrderDraft({ source: 'web',projectId:'' }).then(res => {
              _this.getTableList();
              _this[val] = true
            })
          },
          okText: '使用草稿',
          cancelText: '丢弃草稿',
        })
      } else {
        _this[val] = true
      }

    },
  }
  // 进入路由添加缓存
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.$store.dispatch('operationCachedRoutes/updateAliveRoutes', ['workOrder']);
  //   });
  // },
};
</script>
<style lang="scss" scoped>
.searchBox {
  display: flex;
}

.expandBox {
  color: #1890ff;
  line-height: 40px;
  text-align: right;
  cursor: pointer;
}

.searchBox_but {
  margin-left: 20px;
}

.searchBox_form {
  flex: 1;
}

.searchBox_form_type {
  line-height: 39.9999px;
}

.marginLeft {
  margin-left: 5px;
}

.flex1 {
  flex: 1;
}

.displayFlex {
  display: flex;
}
</style>